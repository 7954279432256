<template>
  <div class="flex flex-col gap-2 text-xs">
    <div v-if="tasks['load-plugin']" class="flex flex-nowrap flex-grow gap-1 items-center">
      <es-spinner size="12" thickness="2" />
      <span class="text-secondary text-center dark:text-dark-secondary">Загрузка плагина..</span>
    </div>
    <template v-else-if="isSystemValid">
      <es-select v-model="certificate"
                 class="h-6 text-primary dark:text-dark-primary ym-hide-content"
                 clearable
                 :load-options="getValidCertificateOptions"
                 placeholder="Выберите сертификат.."
                 reverse-color
                 dense
                 no-options-text="Нет действующих сертификатов">
        <template #description::prepend>
          <es-icon :icon="iClockOutline" />
        </template>
      </es-select>
    </template>
    <div v-else>
      <a :href="usePluginInstructionGetter()" target="_blank" class="text-accent-primary underline cursor-pointer">Установите</a>
      КриптоПро CSP и Browser plug-in</div>
  </div>
</template>

<script setup lang="ts">
import {nextTick, onMounted, watch} from 'vue'
import {EsSelect, EsSpinner, EsIcon} from '@esigndoc/ui'
import {dataUriToUint8} from "@/utils/data-uri-to-uint8";
import {usePluginInstructionGetter} from "@/utils/instruction-getter";
import {useStore} from "@nanostores/vue";
import {
  $certificate,
  $certificateImage,
  $isSystemValid,
  $tasks,
  generateImage, getValidCertificateOptions, updateSystemValidity
} from "@/stores/app-store.ts";
import {useLazyStore} from "@/utils/lazy-store.ts";
import {iClockOutline} from "@/entities/icons.ts";

const isSystemValid = useStore($isSystemValid)
const tasks = useStore($tasks)
const certificate = useLazyStore($certificate, null)

watch(certificate, async () => {
  if (!certificate.value) {
    return
  }
  const savedImg = localStorage.getItem(`certificate:${certificate.value.id}`)
  await $tasks.get()['load-plugin']?.lock.promise

  if (savedImg) {
    $certificateImage.set({ data: savedImg, buffer: dataUriToUint8(savedImg) })
  } else {
    const img = await generateImage()
    if (img?.data) {
      localStorage.setItem(`certificate:${certificate.value.id}`, img.data)
      $certificateImage.set(img)
    }
  }
})

onMounted(async() => {
  await updateSystemValidity()
    await nextTick()
    $certificateImage.set(await generateImage())
})

</script>

<style scoped>

</style>
